<template>
	<div id="user">
		<index>
			<el-breadcrumb separator="/">
				<el-breadcrumb-item>管理</el-breadcrumb-item>
				<el-breadcrumb-item><a style="color: #000000;">人员管理</a></el-breadcrumb-item>
			</el-breadcrumb>
			<div class="content">
				<div class="u-f">
					<div class=" right">
						<div class="title u-f-item u-f-jsb">
							<div>
								共{{ total }}条数据
								<!-- <span class="btn">选择全部</span> -->
							</div>
							<div class="u-f-item">
								<el-input placeholder="请输入教师名搜索" v-model="sousuo" size="small" style="margin-right: 10px;">
									<i slot="prefix" class="el-input__icon el-icon-search"></i>
								</el-input>
								<el-button size="small" type="primary" @click="search">搜索</el-button>
								<el-button size="small" @click="VisibleTap(1)">添加人员</el-button>
								<!-- <el-button size="small">重置密码</el-button>
								<el-button size="small">批量更新</el-button>-->
								<el-button size="small" @click="imports">批量录入</el-button>
								<el-button size="small" type="primary" @click="exportss">导出</el-button>
							</div>
						</div>
						<el-table
							:data="peopleList"
							height="90%"
							ref="multipleTable"
							tooltip-effect="dark"
							@selection-change="handleSelectionChange"
							style="width: 100%;border: 1px solid #EEEEEE;"
						>
							<el-table-column type="selection" width="55" align="center"></el-table-column>
							<el-table-column label="序号" type="index" width="65" align="center"></el-table-column>
							<el-table-column prop="username" label="教师" align="center"></el-table-column>
							<el-table-column prop="mobile" label="电话" align="center"></el-table-column>
							<el-table-column label="性别" align="center">
								<template slot-scope="scope">
									<span v-if="scope.row.gender == 1">男</span>
									<span v-if="scope.row.gender == 2">女</span>
									<span v-if="scope.row.gender == 3">未知</span>
								</template>
							</el-table-column>
							<el-table-column prop="discipline_name" label="学科" align="center">
								<template slot-scope="scope">
									<el-tooltip class="item" effect="dark" :content="scope.row.discipline_name.toString()" placement="top-start">
										<span>{{scope.row.discipline_name.toString()}}</span>
									</el-tooltip>
								</template>
							</el-table-column>
							<el-table-column prop="department_name" label="部门" align="center">
								<template slot-scope="scope">
									<el-tooltip class="item" effect="dark" :content="scope.row.department_name.toString()" placement="top-start">
										<span>{{scope.row.department_name.toString()}}</span>
									</el-tooltip>
								</template>
							</el-table-column>
							<el-table-column prop="group_name" label="角色/职位" align="center"></el-table-column>
							<el-table-column label="操作" width="180" align="center">
								<template slot-scope="scope">
									<el-button @click="VisibleTap(2, scope.row)" type="text" size="small">编辑</el-button>
									<span style="color: #E0E0E0;">|</span>
									<el-button type="text" size="small" v-if="scope.row.status=='normal'" @click="openPower(scope.row)">禁用</el-button>
									<el-button type="text" size="small" v-if="scope.row.status!='normal'" @click="unlock(scope.row)">解锁</el-button>
									<span style="color: #E0E0E0;">|</span>
									<el-button type="text" size="small" @click="updataPad(scope.row)">重置密码</el-button>
								</template>
							</el-table-column>
						</el-table>
						<div class="u-f-item u-f-jsb" style="padding: 15px 0;">
							<el-button type="danger" plain size="mini" @click="delUser">删除</el-button>
							<el-pagination
								@size-change="handleSizeChange"
								@current-change="handleCurrentChange"
								:current-page.sync="page"
								:page-size="limit"
								layout="total, prev, pager, next"
								:total="total"
							></el-pagination>
						</div>
					</div>
				</div>
			</div>
			<!-- 添加 编辑人员-->
			<el-dialog :title="VisibleTitle" :visible.sync="addVisible1" width="500px" :before-close="handleClose">
				<div class="edit-box" style="height: 60vh;overflow-y: auto;">
					<div class="u-f-item input-box">
						<div class="input-lebal">
							<span>*</span>
							名字
						</div>
						<el-input v-model="name" placeholder="请输入名字" style="width: 70%;"></el-input>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal">
							<span>*</span>
							性别
						</div>
						<el-radio-group v-model="sex">
							<el-radio-button label="1">男</el-radio-button>
							<el-radio-button label="2">女</el-radio-button>
						</el-radio-group>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal">
							<span>*</span>
							电话
						</div>
						<el-input v-model="phone" placeholder="请输入电话" style="width: 70%;"></el-input>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal">身份证</div>
						<el-input v-model="identity" placeholder="请输入身份证号码" style="width: 70%;"></el-input>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal">生日</div>
						<el-date-picker v-model="birthday" type="date" placeholder="选择日期" value-format="yyyy-MM-dd"></el-date-picker>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal">学科</div>
						<el-select v-model="school_discipline_id" placeholder="请选择学科" style="width: 70%;" multiple >
							<el-option v-for="item in subjectList" :key="item.id" :label="item.name" :value="item.id" ></el-option>
						</el-select>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal">部门</div>
						<el-select v-model="school_department_id" placeholder="请选择部门" style="width: 70%;" multiple>
							<el-option v-for="item in sectionList" :key="item.id" :label="item.name" :value="item.id"></el-option>
						</el-select>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal">角色/职务</div>
						<!-- multiple -->
						<el-select v-model="group_id" placeholder="请选择角色/职务" style="width: 70%;">
							<el-option v-for="item in roleList" :key="item.id" :label="item.name" :value="item.id"></el-option>
						</el-select>
					</div>
					<!-- <div class="u-f-item input-box">
						<div class="input-lebal">是否科组负责人</div>
						<el-radio-group v-model="is_discipline">
							<el-radio :label="1">是</el-radio>
							<el-radio :label="2">否</el-radio>
						</el-radio-group>
					</div> -->
					<div class="u-f-item input-box">
						<div class="input-lebal">教师类别</div>
						<el-radio-group v-model="identity_type">
							<el-radio :label="1">教师</el-radio>
							<el-radio :label="2">职员</el-radio>
						</el-radio-group>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal">职工类别</div>
						<el-radio-group v-model="staff_type">
							<el-radio :label="1">正编人员</el-radio>
							<el-radio :label="2">非正编人员</el-radio>
						</el-radio-group>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal">QQ</div>
						<el-input v-model="QQ" placeholder="请输入QQ号码" style="width: 70%;"></el-input>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal">邮件</div>
						<el-input v-model="email" placeholder="请输入邮件地址" style="width: 70%;"></el-input>
					</div>
				</div>
				<span slot="footer" class="dialog-footer u-f-item u-f-jsb">
					<el-button @click="closeVisible" size="small">取 消</el-button>
					<el-button type="primary" size="small" @click="submit1">确 定</el-button>
				</span>
			</el-dialog>
			
			<!-- 编辑 -->
			<el-dialog title="编辑部门" :visible.sync="editVisible" width="500px" :before-close="handleClose">
				<div class="edit-box">
					<div class="u-f-item input-box">
						<div class="input-lebal">
							<span>*</span>
							部门名称
						</div>
						<el-input v-model="name" placeholder="请输入名称" style="width: 70%;"></el-input>
					</div>
				</div>
				<span slot="footer" class="dialog-footer u-f-item u-f-jsb">
					<el-button @click="editVisible = false" size="small">取 消</el-button>
					<el-button type="primary" size="small" @click="submit1(2)">确 定</el-button>
				</span>
			</el-dialog>
			<!-- 添加 -->
			<el-dialog title="添加部门" :visible.sync="addVisible" width="500px" :before-close="handleClose">
				<div class="edit-box">
					<div class="u-f-item input-box">
						<div class="input-lebal">
							<span>*</span>
							部门名称
						</div>
						<el-input v-model="name" placeholder="请输入名称" style="width: 70%;"></el-input>
					</div>
				</div>
				<span slot="footer" class="dialog-footer u-f-item u-f-jsb">
					<el-button @click="addVisible = false" size="small">取 消</el-button>
					<el-button type="primary" size="small" @click="submit(1)">确 定</el-button>
				</span>
			</el-dialog>
			
			<!-- 修改密码 -->
			<el-dialog title="重置密码" :visible.sync="upPad" width="500px" :before-close="handleClose">
				<div class="edit-box">
					<div class="u-f-item input-box">
						<div class="input-lebal">
							<span>*</span>
							手机号码
						</div>
						<el-input v-model="mobile" placeholder="请输入手机号码" style="width: 70%;"></el-input>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal">
							<span>*</span>
							密码
						</div>
						<el-input v-model="newpassword" placeholder="请输入密码" style="width: 70%;"></el-input>
					</div>
				</div>
				<span slot="footer" class="dialog-footer u-f-item u-f-jsb">
					<el-button @click="upPad = false" size="small">取 消</el-button>
					<el-button type="primary" size="small" @click="submitPad">确 定</el-button>
				</span>
			</el-dialog>
			
			
			
			<el-dialog title="导入人员" :visible.sync="importVisible" width="500px" :before-close="handleClose">
				<div class="edit-box">
					<div class="u-f-item input-box">
						<div class="input-lebal">
							<span>*</span>
							上传文件
						</div>
						<el-input v-model="file.name" disabled placeholder="请选择要上传的文件" style="width: 60%;margin-right:10px"></el-input>
						<el-button size="middle" style="position: relative;"><input ref="filElem" type="file" class="upload-file" @change="getFile">选择文件</el-button>
					</div>
				</div>
				<span slot="footer" class="dialog-footer u-f-item u-f-jsb">
					<el-button @click="closeimportVisible" size="small">取 消</el-button>
					<el-button type="primary" size="small" @click="submitimport">确 定</el-button>
				</span>
			</el-dialog>
		</index>
	</div>
</template>

<script>
import index from '@/components/common/index.vue';
export default {
	components: {
		index
	},
	data() {
		return {
			upPad:false,
			mobile:'',
			newpassword:'',
			leftIndex:-1,
			condition: '',
			conditionList: [],
			sousuo: '',
			sectionList:[],
			editVisible:false,
			addVisible:false,
			total: 0,
			page: 1,
			limit: 10,
			name: '',
			pid: '',
			school_place_room_id: [],
			roomList: [],
			id: '',
			ids: [],
			userid: '',
			name: '',
			birthday: '',
			page: 1,
			sousuo: '',
			sousuo1: '',
			addVisible1: false,
			VisibleTitle: '添加人员',
			powerVisible: false,
			sex: 1,
			is_discipline: 1,
			is_department: 1,
			identity_type: 1,
			staff_type: 1,
			phone: '',
			identity: '',
			email: '',
			QQ: '',
			group_id: '',
			school_discipline_id: [],
			school_department_id: [],
			subjectList: [],
			peopleList:[],
			roleList:[],
			type:1,
			importVisible:false,
			file:'',
			fileUrl:''
		};
	},
	mounted() {
		this.userList();
		this.getSectionList()
		this.getsubjectList()
		this.getroleList();
	},
	methods: {
		// 导出
		exportss(){
			this.$confirm('确认要导出吗?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
			}).then(() => {
				this.$api.setting.teacherDerive({}).then(res => {
					if (res.data.code == 1) {
						location.href = res.data.data.url;
					} else {
						this.$message.error(res.data.msg);
					}
				});
			}).catch(() => {});
		},
		getFile(e){
			let myfile = this.$refs.filElem;
			let files = myfile.files;
			let file = files[0];
			var formData = new FormData();
			formData.append("file", file);
			this.$api.setting.uploadFile(formData).then(res=>{
				if (res.data.code == 1) {
					this.fileUrl = res.data.data.url;
					if(this.$refs.filElem.files.length){
						this.file = this.$refs.filElem.files[0]
					}
				} else {
					this.$message.error(res.data.msg);
				}
			})
		},
		closeimportVisible(){
			this.importVisible = false;
		},
		// 导入
		imports(){
			this.importVisible = true;
		},
		submitimport(){
			this.$api.setting.teacherImport({
				file:this.fileUrl
			}).then(res=>{
				if (res.data.code == 1) {
					this.$message.success('导入成功');
					this.importVisible = false
					this.userList();
				} else {
					this.$message.error(res.data.msg);
				}
			})
		},
		// 禁用
		openPower(e){
			this.$confirm('确认要禁用该用户吗?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
			}).then(() => {
				this.$api.setting.dimissiontime({
					user_id:e.id
				}).then(res=>{
					if (res.data.code == 1) {
						this.$message.success('禁用成功');
						this.userList();
					} else {
						this.$message.error(res.data.msg);
					}
				})
			}).catch(() => {});
			
		},
		unlock(e){
			this.$confirm('确认要解禁该用户吗?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
			}).then(() => {
				this.$api.setting.relieve({
					user_id:e.id
				}).then(res=>{
					if (res.data.code == 1) {
						this.$message.success('解禁成功');
						this.userList();
					} else {
						this.$message.error(res.data.msg);
					}
				})
			}).catch(() => {});
			
		},
		//修改密码
		updataPad(e){
			this.mobile = e.mobile;
			this.newpassword = '';
			this.upPad = true;
		},
		submitPad(){
			if(this.newpassword==''||!this.newpassword){return this.$message.error('请输入密码');}
			this.$api.setting.resetpwd({
				mobile:this.mobile,
				newpassword:this.newpassword
			}).then(res=>{
				if (res.data.code == 1) {
					this.$message.success('修改成功');
					this.upPad = false;
				} else {
					this.$message.error(res.data.msg);
				}
			})
		},
		leftTap(index){
			this.leftIndex = index;
			this.page = 1;
			this.userList()
		},
		search(){
			this.page = 1;
			this.userList()
		},
		// 添加人员
		addUser() {
			if (this.name == '' || !this.name) {
				return this.$message.error('请输入姓名');
			}
			if (this.phone == '' || !this.phone) {
				return this.$message.error('请输入电话');
			}
			let data = {
				username: this.name,
				gender: this.sex,
				mobile: this.phone,
				group_id: this.group_id, //是 	number 	角色组id
				identity_code: this.identity,
				birthday: this.birthday, // 	否 	date 	生日
				school_discipline_id: this.school_discipline_id, // 	否 	number 	学科ID
				school_department_id: this.school_department_id, // 	否 	number 	部门ID
				// is_discipline: this.is_discipline, // 	否 	number 	是否科组负责人:1=是,2=否
				// is_department: this.is_department, // 	否 	number 	是否部门负责人:1=是,2=否
				identity_type: this.identity_type, // 	否 	number 	身份类别:1=教师,2=职员
				staff_type: this.staff_type, // 	否 	number 	职工类别:1=正编人员,2=非正编人员
				qq: this.QQ, // 	否 	number
				email: this.email,
				// campus_id:this.condition
			};
			this.$api.setting.addUser(data).then(res => {
				if (res.data.code == 1) {
					this.$message.success('添加成功');
					this.initialize();
					this.userList();
					this.addVisible1 = false;
				} else {
					this.$message.error(res.data.msg);
				}
			});
		},
		// 编辑人员
		editUser() {
			if (this.name == '' || !this.name) {
				return this.$message.error('请输入姓名');
			}
			if (this.phone == '' || !this.phone) {
				return this.$message.error('请输入电话');
			}
			let data = {
				id: this.userid,
				username: this.name,
				gender: this.sex,
				mobile: this.phone,
				group_id: this.group_id, //是 	number 	角色组id
				identity_code: this.identity,
				birthday: this.birthday, // 	否 	date 	生日
				school_discipline_id: this.school_discipline_id, // 	否 	number 	学科ID
				school_department_id: this.school_department_id, // 	否 	number 	部门ID
				// is_discipline: this.is_discipline, // 	否 	number 	是否科组负责人:1=是,2=否
				// is_department: this.is_department, // 	否 	number 	是否部门负责人:1=是,2=否
				identity_type: this.identity_type, // 	否 	number 	身份类别:1=教师,2=职员
				staff_type: this.staff_type, // 	否 	number 	职工类别:1=正编人员,2=非正编人员
				qq: this.QQ, // 	否 	number
				email: this.email,
				// campus_id:this.condition
			};
			this.$api.setting.editUser(data).then(res => {
				if (res.data.code == 1) {
					this.$message.success('添加成功');
					this.initialize();
					this.userList();
					this.addVisible1 = false;
				} else {
					this.$message.error(res.data.msg);
				}
			});
		},
		// 添加、编辑人员
		submit1() {
			if (this.type == 1) {
				this.addUser();
			} else if (this.type == 2) {
				this.editUser();
			}
		},
		// 获取角色列表
		getroleList() {
			this.$api.pepople.roleList().then(res => {
				if (res.data.code == 1) {
					let list = res.data.data.rows;
					this.roleList = list;
				}
			});
		},
		// 删除人员
		delUser() {
			if (this.ids.length) {
				this.$confirm('确定要删除？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消'
				}).then(() => {
					this.$api.setting
						.delUser({
							id: this.ids
						})
						.then(res => {
							if (res.data.code == 1) {
								this.$message.success('删除成功');
								this.userList();
							} else {
								this.$message.error(res.data.msg);
							}
						});
				});
			} else {
				this.$message.error('请选择要删除的人员');
			}
		},
		// 弹窗
		VisibleTap(type, item) {
			this.type = type;
			if (type == 1) {
				this.VisibleTitle = '添加人员';
			} else if (type == 2) {
				console.log(item);
				this.VisibleTitle = '编辑人员';
				this.userid = item.id;
				this.name = item.username;
				this.sex = Number(item.gender);
				this.phone = item.mobile;
				this.group_id = item.group_id;
				this.identity = item.identity_code;
				this.birthday = item.birthday;
				this.school_discipline_id = item.discipline_id;
				this.school_department_id = item.department_id;
				// this.is_discipline = Number(item.is_discipline);
				// this.is_department = Number(item.is_department);
				this.identity_type = Number(item.identity_type);
				this.staff_type = Number(item.staff_type);
				this.QQ = item.qq;
				this.email = item.email;
			}
			this.addVisible1 = true;
		},
		
		// 初始化表单
		initialize() {
			this.name = '';
			this.sex = 1;
			this.phone = '';
			this.group_id = ''; //是 	number 	角色组id
			this.identity = '';
			this.birthday = ''; // 	否 	date 	生日
			this.school_discipline_id = ''; // 	否 	number 	学科ID
			this.school_department_id = ''; // 	否 	number 	部门ID
			this.is_discipline = 1; // 	否 	number 	是否科组负责人:1=是,2=否
			this.is_department = 1; // 	否 	number 	是否部门负责人:1=是,2=否
			this.identity_type = 1; // 	否 	number 	身份类别:1=教师,2=职员
			this.staff_type = 1; // 	否 	number 	职工类别:1=正编人员,2=非正编人员
			this.QQ = ''; // 	否 	number
			this.email = '';
			this.name = '';
			this.pid = '';
			this.school_place_room_id = []
		},
		// 关闭弹窗
		closeVisible() {
			this.initialize();
			this.addVisible1 = false;
		},
		// search(){
		// 	if(this.sousuo){
		// 		this.page = 1;
		// 		this.getSectionList()
		// 	}
		// },
		// 学科信息
		getsubjectList() {
			this.$api.setting.subjectList({}).then(res => {
				if (res.data.code == 1) {
					let list = res.data.data;
					this.subjectList = list;
				} else {
					this.$message.error(res.data.msg);
				}
			});
		},
		// 人员列表
		userList() {
			let data = {};
			if (this.sousuo) {
				data.username = this.sousuo;
			}
			if(this.leftIndex>-1){
				data.school_department_id = this.sectionList[this.leftIndex].id
			}
			this.$api.setting.userList({
				limit: this.limit,
				page: this.page,
				filter: JSON.stringify(data),
				op: { username: 'like',school_department_id:'='}
			}).then(res => {
				if (res.data.code == 1) {
					this.peopleList = res.data.data.rows;
					this.total = res.data.data.total;
				}
			});
		},
		// 关闭弹窗
		handleClose (done) {
			this.editVisible = false;
			this.addVisible = false
			this.addVisible1 = false
			this.initialize()
			done()
		},
		handleSelectionChange(val) {
			let arr = [];
			for(let i in val){
				arr.push(val[i].id);
			}
			this.ids = arr;
			console.log(this.ids)
		},
		handleSizeChange(val) {
			console.log(`每页 ${val} 条`);
		},
		handleCurrentChange(val) {
			this.page = val;
			this.userList()
		},
		openDialog(type, item){
			if(type == 1){
				this.addVisible = true
				if(item){
					this.pid = item.id
				}else{
					this.pid = 0;
				}
				console.log(item)
			}else{
				this.editVisible = true;
				this.id = item.id;
				this.name = item.name;
				this.pid = item.pid;
				this.school_place_room_id = item.school_place_room_id;
			}
		},
		// 房间列表
		getRoomList(){
			this.$api.setting.getPlaceRoomList({}).then(res=>{
				if(res.data.code==1){
					this.roomList  = res.data.data.room.rows
				}
			})
		},
		// 获取部门列表
		getSectionList(){
			this.$api.setting.sectionList({}).then(res=>{
				if(res.data.code==1){
					let data = res.data.data;
					let arr = [];
					for (let i in data) {
						arr.push(data[i])
					}
					this.sectionList = arr;
					this.sectionList = this.sectionList.concat(data[0].children);
					console.log(this.sectionList)
				}
			})
		},
		submit(type){
			let api = this.$api.setting.sectionAdd;
			let data = {
				name: this.name,
				pid: this.pid||0
			}
			if(type==2){
				data.id = this.id;
				api = this.$api.setting.sectionEdit
			}
			api(data).then(res=>{
				if(res.data.code==1){
					if(type==1){
						this.$message.success('添加成功');
					}else{
						this.$message.success('修改成功');
					}
					this.addVisible = false;
					this.editVisible = false;
					this.initialize();
					this.getSectionList()
				}else {
					this.$message.error(res.data.msg);
				}
			})
		},
		delPlace(id){
			let _this = this;
			_this.$confirm('确定要删除？','提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消'
			}).then(()=>{
				_this.$api.setting.sectionDel({
					id:id
				}).then(res=>{
					if (res.data.code == 1) {
						_this.$message.success('删除成功');
						_this.getSectionList();
					} else {
						_this.$message.error(res.data.msg);
					}
				})
			})
		}
	}
};
</script>

<style lang="scss">
#user {
	.head {
		background-color: #ffffff;
		padding: 10px 30px;
		margin-top: 15px;
		.span {
			margin-right: 10px;
			background-color: #f9f9f9;
			color: #8d9da6;
			padding: 3px 10px;
		}
	}
	.content {
		margin-top: 20px;
		background-color: #ffffff;
		height: 85vh;
		.left {
			border-right: 1px solid #eeeeee;
			height: 85vh;
			min-width: 300px;
			.add {
				background-color: #f8f8f8;
				padding: 10px 0;
				font-size: 24px;
				color: #8d9da6;
				cursor: pointer;
			}
			.add:hover {
				color: #007aff;
			}
			.building-list {
				padding: 25px;
				height: 75vh;
				overflow-y: auto;
				.item {
					padding: 0 15px;
					height: 40px;
					line-height: 40px;
					margin-top: 15px;
					background-color: #f8f8f8;
					.el-icon-caret-right,
					.el-icon-caret-bottom {
						color: #a4a4a4;
						margin-right: 5px;
						font-size: 16px;
					}
					.el-checkbox {
						display: block;
					}
					.btn {
						i {
							background-color: #e8eaec;
							color: #a4a4a4;
							width: 25px;
							height: 25px;
							margin-left: 10px;
							border-radius: 50%;
						}
						i:hover {
							color: red;
						}
					}
				}
				.active{
					background-color: #E4F1FF;
					color: #007AFF;
				}
				.floor-list {
					background-color: #f8f8f8;
					border-top: 1px solid #eeeeee;
					padding: 0 15px 15px 30px;
					.floor-item {
						background-color: #f1f1f1;
						padding: 0 15px 0 25px;
						color: #4c5559;
						height: 40px;
						line-height: 40px;
						margin-top: 15px;
						.btn {
							i {
								background-color: #ffffff;
								color: #a4a4a4;
								width: 25px;
								height: 25px;
								margin-left: 10px;
								border-radius: 50%;
							}
							i:hover {
								color: red;
							}
						}
					}
					.active {
						background-color: #e4f1ff;
						color: #007aff;
					}
				}
			}
		}
		.right {
			height: 78vh;
			width: 100%;
			padding: 25px 50px 0 50px;
			.title {
				padding: 0 0 15px 0;
			}
		}
	}
	
	.el-table th > .cell,
	.el-table .cell {
		overflow: hidden; /*超出部分隐藏*/
		white-space: nowrap; /*不换行*/
		text-overflow: ellipsis; /*超出部分文字以...显示*/
	}
	.el-table thead {
		background: #fafafa !important;
	}
	.el-table th {
		padding: 15px 5px !important;
		background: #fafafa !important;
	}
	.el-table::before {
		left: 0;
		bottom: 0;
		width: 100%;
		height: 0;
	}
	
	.el-dialog__header {
		border-bottom: 1px solid #eeeeee;
		padding: 10px 20px;
		.el-dialog__title {
			font-size: 16px;
		}
	}
	.el-dialog__body {
		padding: 0 20px 35px 20px;
		.edit-box {
			.input-box {
				margin-top: 35px;
				padding-left: 15px;
				.input-lebal {
					width: 20%;
					text-align: right;
					color: #aeb5b8;
					margin-right: 10px;
					span {
						color: #ff7a65;
						margin-right: 3px;
					}
				}
				.el-icon-remove-outline {
					font-size: 1.5625vw;
					color: #d7d9db;
					margin-left: 10px;
				}
			}
			.upload-file{
				position: absolute;
				left: 0;
				right: 0;
				top: 0;
				bottom: 0;
				opacity: 0;
				cursor: pointer;
			}
			.avatar-uploader .el-upload {
				border: 1px dashed #d9d9d9;
				border-radius: 6px;
				cursor: pointer;
				position: relative;
				overflow: hidden;
			}
			.avatar-uploader .el-upload:hover {
				border-color: #409eff;
			}
			.avatar-uploader-icon {
				font-size: 28px;
				color: #8c939d;
				width: 90px;
				height: 90px;
				line-height: 90px;
				text-align: center;
			}
			.avatar {
				width: 90px;
				height: 90px;
				display: block;
			}
		}
	}
	.el-dialog__footer {
		padding: 15px 20px 15px;
		box-shadow: 0px -5px 10px -5px #eeeeee;
	}
}
</style>
